import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import tabletTrialImg from "../images/30-day-burst-tablet-grey.png"

const ToolSteps = () => (
  <SiteToolSteps>
    <div className="tool__steps-wrapper">
      <div className="steps">
        <h3>Inside the Tool: 5 Steps to success</h3>
        <h6>1. Set Up Your Users</h6>
        <p>
          Create accounts for employees or contractors you’re ready to go. You
          control what they see and when they work.
        </p>
        <h6>2. Build your site</h6>
        <p>
          Easily upload customer info from your accounting system. Use unlimited
          cloud storage to add information in your secure document library -
          anything from company rules to installation instructions. Create
          custom checklists to make sure they do it the right way, every time.
        </p>
        <h6>3. Put each job on a schedule</h6>
        <p>
          Be more efficient and learn who on your team is meeting deadlines.
          Time stamp your work tickets to set expectations and balance multiple
          jobs. Build on-time performance into your culture.
        </p>
        <h6>4. Assign a Job and attach checklists</h6>
        <p>
          An alert is sent to your tech and an assignment is made. Monitor job
          status in real time and use the in-app communicator to track comments
          and questions. When the work is done and the customer satisfaction
          checklist is complete, your techs can include a confirmation picture
          and close the ticket.
        </p>
        <h6>5. Focus on the customers you don’t have</h6>
        <p>
          Receive alerts on every open job. See when they’ve been completed or
          require re-assignment. Look at all your open and completed jobs by
          job, by employee or by contractor. On your laptop or on your phone,
          you can manage your workflow from anywhere while focusing on growing
          your business.
        </p>
      </div>
      <div className="vid-wrapper">
        <iframe
          src="https://player.vimeo.com/video/411427422"
          title="overview"
          className="vid"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
        <Link to="/get-started/">
          <img
            className="trial-img"
            src={tabletTrialImg}
            alt="Get started with a 30 day free trial"
          />
        </Link>
      </div>
    </div>
  </SiteToolSteps>
)

export default ToolSteps

const SiteToolSteps = styled.div`
  .tool__steps-wrapper {
    display: flex;
    margin: auto;
    max-width: ${props => props.theme.section.width};
    padding: 55px 35px;

    .steps {
      padding-right: 30px;

      h6 {
        color: ${props => props.theme.color.black};
        font-size: ${props => props.theme.font.size.medium};
        margin: 28px 0 4px;
      }
    }

    .vid-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 700px;

      .vid {
        width: 640px;
        height: 360px;
      }

      .trial-img {
        margin-top: 50px;
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.sectionWidth}) {
      .vid-wrapper > .vid {
        width: 480px;
        height: 270px;
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.widescreen}) {
      padding: 40px;
      flex-direction: column;

      .steps {
        padding-right: 0;
      }

      .vid-wrapper {
        flex-direction: row;
        margin-top: 30px;
        width: 100%;

        .vid {
          width: 320px;
          height: 180px;
        }

        .trial-img {
          margin: 0;
          height: 180px;
          width: 320px;
        }
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      .vid-wrapper {
        align-items: center;
        flex-direction: column;
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.largePhone}) {
      padding: 30px;
    }

    @media (max-width: ${props => props.theme.breakpoint.mobile}) {
      .vid-wrapper {
        .vid,
        .trial-img {
          width: 240px;
          height: 135px;
        }
      }
    }
  }
`
