import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Banner from "../components/banner"
import ToolSteps from "../components/toolSteps"
import IconSection from "../components/iconSection"
import TestimonialSection from "../components/testimonialSection"
import bg from "../images/hero-2.png"

const TheTool = () => {
  const title = "THE TOOL"
  const text =
    "Simple, customizable work tickets to manage a remote workforce. From anywhere."

  return (
    <Layout>
      <SEO {...{ title }} />
      <Banner {...{ bg, title, text }} />
      <ToolSteps />
      <IconSection />
      <TestimonialSection />
    </Layout>
  )
}

export default TheTool
